<template>
  <div v-if="themeLoaded">
    <Button
      :tenant-style-guide="tenantStyleGuide"
      :button="{value: 'previews', text: tenantMessages.operationDetails.action.text }"
      @active-button="handleBackButton"
    />
    <OperationHeader
      style="margin: 20px 0;"
      :order-amount="operationDetails.external_order_amount_in_cents"
      :operation-status="operationDetails.status"
      :operation-amount="operationDetails.cashback_amount_in_cents"
      :tenant-style-guide="tenantStyleGuide"
    />

    <OperationTimeline
      :created-at="operationDetails.created_at"
      :confirmed-at="operationDetails.settlement_date"
      :tenant-style-guide="tenantStyleGuide"
      :status="operationDetails.status"
    />

    <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary, marginBottom: '0' }" >
      <strong>
        {{ tenantMessages.operationDetails.description.title }}:
      </strong>
    </p>
    <section class="operationDetails" :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
      <div class="operationDetails__column">
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.type }}:
          </strong>
          {{ handleOperationType(operationDetails.type) }}
        </p>
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.orderId }}:
          </strong>
          {{ operationDetails.external_order_id }}
        </p>
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.createdAt }}:
          </strong>
          {{ dateFormatter(operationDetails.created_at)}}
        </p>
      </div>
      <div  class="operationDetails__column">
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.orderAmount }}:
          </strong>
          {{ currencyFormatter(operationDetails.external_order_amount_in_cents) }}
        </p>
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.cashbackAmount }}:
          </strong>
          {{ currencyFormatter(operationDetails.cashback_amount_in_cents) }}
        </p>
        <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
          <strong>
            {{ tenantMessages.operationDetails.description.appliedBalance }}:
          </strong>
          {{ currencyFormatter(operationDetails.applied_balance_in_cents) }}
        </p>
      </div>
    </section>
    <p>
      <strong :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }">
        {{ tenantMessages.operationDetails.cashbackPrograms.title }}:
      </strong>
    </p>
    <TableBody :tenant-style-guide="tenantStyleGuide">
      <template v-slot:header>
        <TableHeader :table-data="programsTableHeaders" :tenant-style-guide="tenantStyleGuide"/>
      </template>
      <template v-slot:row>
        <TableRow
          v-for="program in programsList"
          :key="program.expire_order_id"
          :tenant-style-guide="tenantStyleGuide"
          :table-data-column="[
            {
              type: 'text',
              value:  program.cashback_program_title,
            },
            {
              type: 'currency',
              value: program.cashback_amount_in_cents,
            },
            {
              type: 'text',
              value: program.days_to_expire_cashback === 0 ? 'Sem Prazo' : `${program.days_to_expire_cashback} dia(s)`,
            },
            {
              type: 'date',
              value: program.cashback_expired_at || 'Never Expire',
            }
          ]"
        />
      </template>
    </TableBody>
  </div>
</template>

<script>
import dateFormatter from '../../utils/formatDate'
import currencyFormatter from '../../utils/formatCurrency'

import { getOperationsDetails, getOperationsPrograms } from '../../api/operation'
import { getWalletTheme } from '../../api/tenantSettings'

import DefaultTheme from '../../theme/default.json'
import BlankTheme from '../../theme/blank.json'
import DefaultMessages from '../../messages/default.json'

import Button from '../../components/Button'
import TableRow from '../../components/Table/TableRow'
import TableBody from '../../components/Table/TableBody'
import TableHeader from '../../components/Table/TableHeader'
import OperationHeader from '../../components/OperationHeader'
import OperationTimeline from '../../components/OperationTimeline'

export default {
  name: 'OperationDetails',
  components: {
    Button,
    TableRow,
    TableBody,
    TableHeader,
    OperationHeader,
    OperationTimeline
  },
  data () {
    return {
      themeLoaded: false,
      tenantMessages: {},
      tenantStyleGuide: {},
      programsList: [],
      operationDetails: {},
      info: {},
      programsTableHeaders: [
        'Nome',
        'Valor de cashback',
        'Prazo do cashback',
        'Data de Expiração'
      ]
    }
  },
  created () {
    this.setData()
    this.tenantStyleGuide = BlankTheme
    this.tenantMessages = DefaultMessages
    this.getMyThemeColor()
    this.fetchData()
  },
  methods: {
    setData () {
      this.info = JSON.parse(localStorage.getItem('info'))
    },
    async fetchData () {
      try {
        this.fetchProgramsList()
        this.fetchOperationDetails()
      } catch (error) {
        return true
      }
    },
    async getMyThemeColor () {
      try {
        const { data } = await getWalletTheme(this.info.apiKey)
        this.tenantStyleGuide = { ...data.data, DefaultTheme }
        this.themeLoaded = true
      } catch {
        this.tenantStyleGuide = DefaultTheme
        this.themeLoaded = true
      }
    },
    async fetchProgramsList () {
      try {
        const { data } = await getOperationsPrograms(this.info.externalRefId, this.$route.params.id, this.info.apiKey)
        this.programsList = data.data
      } catch (error) {
        return true
      }
    },
    async fetchOperationDetails () {
      try {
        const { data } = await getOperationsDetails(this.info.externalRefId, this.$route.params.id, this.info.apiKey)
        this.operationDetails = data.data
      } catch (error) {
        return true
      }
    },
    handleOperationType (type) {
      let typesMessage = this.tenantMessages.types.purchase
      if (type === 'purchase') {
        typesMessage = this.tenantMessages.types.purchase
      }

      if (type === 'chargeback') {
        typesMessage = this.tenantMessages.types.chargeback
      }

      if (type === 'expiration') {
        typesMessage = this.tenantMessages.types.expiration
      }

      if (type === 'incentive') {
        typesMessage = this.tenantMessages.types.incentive
      }

      return typesMessage
    },
    dateFormatter (value) {
      return dateFormatter.brazilianFormat(value)
    },
    currencyFormatter (value) {
      return currencyFormatter.formatInReais(value / 100)
    },
    handleBackButton () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.operationDetails
  @include display-row
  flex-grow: 1

  &__column
    width: 100%

.previewsButton
  @include display-row
  align-items: center
  cursor: pointer
  justify-content: space-around
  width: 80px
  border: none
  background: #FFF

</style>
