<template>
  <button
    class="button"
    :id="button.value"
    :value="button.value"
    @mouseover="mouseIn"
    @mouseleave="mouseOut"
    @click="onClick"
    :style="{
      border: tenantStyleGuide.content.boxBorder,
      letterSpacing: tenantStyleGuide.content.buttonLetterSpacing,
      fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
      textTransform: tenantStyleGuide.content.buttonTextTransform,
      borderRadius: tenantStyleGuide.content.boxBorderRadius,
      fontWeight: tenantStyleGuide.content.buttonFontWeight,
      padding: tenantStyleGuide.content.buttonPadding || '10px'
    }"
  >
    {{ button.text }}
  </button>
</template>

<script>
import DefaultTheme from '../../theme/default.json'

export default {
  name: 'Button',
  emits: ['activeButton'],
  props: {
    button: {
      type: Object,
      default: () => ({}),
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      active: ''
    }
  },
  methods: {
    mouseIn (event) {
      if (event.target.id !== this.active) {
        document.getElementById(event.target.id).style.background = `${this.tenantStyleGuide.content.buttonBackgroundHover}`
        document.getElementById(event.target.id).style.color = `${this.tenantStyleGuide.content.buttonFontHover}`
      }
    },
    mouseOut (event) {
      if (event.target.id !== this.active) {
        document.getElementById(event.target.id).style.background = `${this.tenantStyleGuide.content.buttonBackgroundInactive}`
        document.getElementById(event.target.id).style.color = `${this.tenantStyleGuide.content.fontPrimary}`
      }
    },
    onClick (event) {
      if (this.active !== event.target.id && this.active !== '') {
        document.getElementById(this.active).style.background = `${this.tenantStyleGuide.content.buttonBackgroundInactive}`
        document.getElementById(this.active).style.color = `${this.tenantStyleGuide.content.fontPrimary}`
        document.getElementById(event.target.id).style.background = `${this.tenantStyleGuide.content.buttonBackgroundActive}`
        document.getElementById(event.target.id).style.color = `${this.tenantStyleGuide.content.buttonFontActive}`
        this.active = event.target.id
      } else {
        document.getElementById(event.target.id).style.background = `${this.tenantStyleGuide.content.buttonBackgroundActive}`
        document.getElementById(event.target.id).style.color = `${this.tenantStyleGuide.content.buttonFontActive}`
        this.active = event.target.id
      }
      this.$emit('activeButton', event.target._value)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.button
  border: none
  padding: 10px
  max-width: 100%
  min-width: 40px
  background: #FFF
  @include display-row
  align-items: center
  justify-content: space-around

  &:hover
    cursor: pointer

</style>
