import request from '../utils/request'

export function getOperationsPrograms (externalRefId, externalOrderId, apiKey) {
  return request({
    url: `/users/${externalRefId}/operations/${externalOrderId}/cashback-programs`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export function getOperationsDetails (externalRefId, externalOrderId, apiKey) {
  return request({
    url: `/users/${externalRefId}/operations/${externalOrderId}`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export default {
  getOperationsDetails,
  getOperationsPrograms
}
