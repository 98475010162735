<template>
  <div class="container">
    <div class="operationDetails" :style="{ background: tenantStyleGuide.content.boxBackground, border: tenantStyleGuide.content.boxBorder, borderRadius: tenantStyleGuide.content.boxBorderRadius }" >
      <div class="operationDetails__item">
        <div class="operationDetails__item--icon" :style="{ color: tenantStyleGuide.content.fontPrimary, background: tenantStyleGuide.content.backgroundSecondary, border: tenantStyleGuide.tags.border}">
          <div class="operationDetails__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.bag)" :style="{ fill: tenantStyleGuide.content.fontPrimary, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize }" />
        </div>
        <div class="operationDetails__item--text">
          <h1 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontSecondary, letterSpacing: tenantStyleGuide.global.letterSpacing, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.operationHeader.operationTitles.orderAmount.title }} </h1>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary, letterSpacing: tenantStyleGuide.global.letterSpacing }"> {{ currencyFormatter(orderAmount)}} </h2>
        </div>
      </div>

      <div class="operationDetails__divider" :style="{ border: tenantStyleGuide.content.boxBorder }" />

      <div class="operationDetails__item">
        <div class="operationDetails__item--icon" :style="{ color: tenantStyleGuide.content.fontPrimary, background: tenantStyleGuide.content.backgroundSecondary, border: tenantStyleGuide.tags.border }">
          <div class="operationDetails__item--icon-color">
            <div class="operationDetails__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.cashbackIn)" :style="{ fill: tenantStyleGuide.content.fontPrimary, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize}" />
          </div>
        </div>
        <div class="operationDetails__item--text">
          <h1 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontSecondary, letterSpacing: tenantStyleGuide.global.letterSpacing, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.operationHeader.operationTitles.operationStatus.title }} </h1>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: handleOperationStatusColor, letterSpacing: tenantStyleGuide.global.letterSpacing}">  {{ handleOperationStatusMessage }} </h2>
        </div>
      </div>

     <div class="operationDetails__divider" :style="{ border: tenantStyleGuide.content.boxBorder }" />

      <div class="operationDetails__item">
        <div class="operationDetails__item--icon" :style="{ color: tenantStyleGuide.content.fontPrimary, background: tenantStyleGuide.content.backgroundSecondary, border: tenantStyleGuide.tags.border }">
          <div class="operationDetails__item--icon-color">
            <div class="operationDetails__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.cashbackOut)" :style="{ fill: tenantStyleGuide.content.fontPrimary, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize }" />
          </div>
        </div>
        <div class="operationDetails__item--text">
          <h1 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontSecondary, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.operationHeader.operationTitles.operationAmount.title }} </h1>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }"> {{ currencyFormatter(operationAmount)}} </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import convertBase64 from '../../utils/base64'
import currencyFormatter from '../../utils/formatCurrency'

import DefaultTheme from '../../theme/default.json'
import DefaultMessages from '../../messages/default.json'

export default {
  name: 'OperationHeader',
  props: {
    orderAmount: {
      type: Number,
      require: false,
      default: 0
    },
    operationStatus: {
      type: String,
      require: false,
      default: ''
    },
    operationAmount: {
      type: Number,
      require: false,
      default: 0
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  computed: {
    handleOperationStatusMessage () {
      let operationStatusMessage = this.tenantMessages.status.fulfilled
      if (this.operationStatus === 'fulfilled') {
        operationStatusMessage = this.tenantMessages.status.fulfilled
      }
      if (this.operationStatus === 'pending') {
        operationStatusMessage = this.tenantMessages.status.pending
      }
      if (this.operationStatus === 'canceled') {
        operationStatusMessage = this.tenantMessages.status.canceled
      }
      if (this.operationStatus === 'denied') {
        operationStatusMessage = this.tenantMessages.status.denied
      }
      return operationStatusMessage
    },
    handleOperationStatusColor () {
      let operationStatusMessage = this.tenantStyleGuide.status.fulfilled
      if (this.operationStatus === 'fulfilled') {
        operationStatusMessage = this.tenantStyleGuide.status.confirmed
      }
      if (this.operationStatus === 'pending') {
        operationStatusMessage = this.tenantStyleGuide.status.pending
      }
      if (this.operationStatus === 'canceled') {
        operationStatusMessage = this.tenantStyleGuide.status.canceled
      }
      if (this.operationStatus === 'denied') {
        operationStatusMessage = this.tenantStyleGuide.status.denied
      }
      return operationStatusMessage
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  methods: {
    svgIcon (value) {
      return convertBase64(value)
    },
    currencyFormatter (value) {
      return currencyFormatter.formatInReais(value / 100)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.container
  @include display-row
  margin: auto
  width: 100%
  @include mobile-and-down
    flex-direction: column

.operationDetails
  @include display-row
  justify-content: center
  flex-wrap: wrap
  width: 100%
  @include mobile-and-down
    @include display-col

  & h3
    font-size: 20px

  & p
    font-size: 12px

  &__item
    @include display-row
    align-items: center
    justify-content: center
    margin: 25px auto
    @include mobile-and-down
      justify-content: left
      margin: 20px

    &--icon
      width: 46px
      height: 46px
      display: flex
      justify-content: center
      margin-right: 20px
      align-items: center
      border-radius: 100%

      &-color
        display: flex
        align-items: center

        & svg path
          fill: currentcolor

    &--text
      & h1
        font-size: 12px
        margin: 0

      & h2
        font-size: 30px
        line-height: 30px
        margin: 5px 0

      & p
        margin: 2px 0

  &__divider
    width: 0
    height: 100%
    margin: 0 10px
    @include mobile-and-down
      width: 100%
      height: 0px
      margin: 0

</style>
