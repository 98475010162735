<template>
  <div
    class="timeline"
    :style="{
      borderRadius: tenantStyleGuide.content.boxBorderRadius
    }"
  >
    <div
      class="timeline__data"
      :style="{
        background: handleCashbackStatusBackground
      }"
    />
    <div class="timeline__divider" />
    <div class="timeline__data"
      :style="{
        background: status === 'pending' ? 'grey' : handleCashbackStatusBackground
      }"
    />
  </div>
  <div
    class="timeline__info"
     :style="{
      letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
      color: tenantStyleGuide.content.fontPrimary
    }"
  >
    <div>
      <p :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginBottom: '5px'
        }"
      >
        {{ dateFormatter(createdAt) }}
      </p>
      <p :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginBottom: '0'
        }"
      >
        <strong>
          {{ tenantMessages.walletTimeline.createdAt.title }}
        </strong>
      </p>
    </div>
    <div>
      <p :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginBottom: '5px'
        }"
      >
        {{ dateFormatter(confirmedAt) }}
      </p>
      <p :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginBottom: '0'
        }"
      >
        <strong>
          {{ handleCashbackStatusMessages }}
        </strong>
      </p>
    </div>
  </div>
</template>

<script>
import dateFormatter from '../../utils/formatDate'

import DefaultTheme from '../../theme/default.json'
import DefaultMessages from '../../messages/default.json'

export default {
  name: 'OperationTimeline',
  props: {
    createdAt: {
      type: String,
      default: '',
      require: false
    },
    confirmedAt: {
      type: String,
      default: '',
      require: false
    },
    status: {
      type: String,
      default: '',
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  computed: {
    handleCashbackStatusMessages () {
      let statusMessage = this.tenantMessages.walletTimeline.confirmedAt.status.fulfilled
      if (this.status === 'fulfilled') {
        statusMessage = this.tenantMessages.walletTimeline.confirmedAt.status.fulfilled
      }
      if (this.status === 'pending') {
        statusMessage = this.tenantMessages.walletTimeline.confirmedAt.status.pending
      }
      if (this.status === 'canceled') {
        statusMessage = this.tenantMessages.walletTimeline.confirmedAt.status.canceled
      }
      if (this.status === 'denied') {
        statusMessage = this.tenantMessages.walletTimeline.confirmedAt.status.denied
      }
      return statusMessage
    },
    handleCashbackStatusBackground () {
      let statusBackgroundColor = this.tenantStyleGuide.status.confirmed
      if (this.status === 'fulfilled') {
        statusBackgroundColor = this.tenantStyleGuide.status.confirmed
      }
      if (this.status === 'pending') {
        statusBackgroundColor = this.tenantStyleGuide.status.pending
      }
      if (this.status === 'canceled') {
        statusBackgroundColor = this.tenantStyleGuide.status.canceled
      }
      if (this.status === 'denied') {
        statusBackgroundColor = this.tenantStyleGuide.status.denied
      }
      return statusBackgroundColor
    }
  },
  methods: {
    dateFormatter (value) {
      return dateFormatter.brazilianFormat(value)
    }
  }

}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"

.timeline
  @include display-row
  overflow: hidden

  &__data
    height: 10px
    width: 100%

  &__divider
    height: 10px
    width: 10px

  &__info
     @include display-row
     justify-content: space-around
     text-align: center
</style>
